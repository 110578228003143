import React, {Component} from 'react';
import styled, { css } from "styled-components";
import {media} from "utils/Media"
import StyledButton from "../shared/StyledButton"
import CustomerModal from "./CustomerModal";
import corinthia from "../../images/corinthia.jpg"
import todaytix from "../../images/ttx_logo_horizontal_color.svg"
import hotel from "../../images/icons/ticket.png"
import googlePlay from "../../images/google-play.png"
import appStore from "../../images/app-store.png"
import { Link } from 'gatsby';

const Wrapper = styled.div`
    .narrow {
        max-width: 600px;
        margin: 0 auto;
    }
    .block {
        display: block;
    } 
    .mt-2 {
        margin-top: 2rem;
    }
`

const Content = styled.div`
    position: relative;
    margin: 0 auto;
    text-align: center;
    max-width: 900px;
    padding: 0 1rem;
`

const Box = styled.div`
    background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
    padding: 3px;
    margin: 2rem auto;
    
    .helper {
        background: ${props => props.theme.colors.white};
        height: 100%;
        padding: 1rem;
        p strong {
            color: #000;
            font-family: ${props => props.theme.font.family.black}!important;
            line-height: ${props => props.theme.font.lineHeight.headings}!important;
            font-style: italic;
            font-weight: 900;
        }
        hr {
            height: 4px;
            background: #000;
            width: 80%;
            margin: 2rem auto;
            border: none;
        }
    }
    .accelerator {
        @media ${media.lg} {
            font-size: 1.8rem;
        }
    }
    p a {
        color: ${props => props.theme.colors.primary};
        text-decoration: underline;
    }
`

const CustomStyledButton = styled(StyledButton)`
    text-transform: none;
    max-width: 320px;
    width: 100%;
    
    div span{
        padding: 1rem 0;
    }
    
    
    h3 {
        font-style: italic;
        font-size: 90%;
        color: ${props => props.theme.colors.white};
        
        @media ${media.md} {
            font-size: 110%;
        }
    }
    
    p {
        font-family: Proxima Nova W01 Regular, Arial, sans-serif;
        
        font-size: 80%;
        
        @media ${media.md} {
            font-size: 100%;
        }
    }
`


const LogoWrapper = styled.div`
    background: black;
    text-align: center;
    padding: 3rem 0;
    margin: 0;
    ${props => props.second && css`
        padding-top: 0px;
    `}
    .helper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        max-width: 1200px;
        margin: 0 auto;
      
    }
    
    .imageWrapper {
        max-width:150px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        img {
            margin-bottom: 2rem;
        }
        @media ${media.lg} {
            max-width:218px;
        }
        @media ${media.xl} {
            max-width:248px;
        }
    }
    
    .item { 
         @media ${media.md} {
            width: 70%;
            padding: 1rem;
         }
    }
    
    .bold {
        font-family: ${props => props.theme.font.family.black};
        line-height: ${props => props.theme.font.lineHeight.headings};
        font-weight: 900;
    }
`

class GroupsBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customerModalOpen: false,
        }
    }

    toggleCustomerModal = (e) => {
        e.preventDefault();

        this.setState({
            customerModalOpen: !this.state.customerModalOpen
        });
    };

    render() {
        return (
            <Wrapper>
                <Content className="my-3 my-md-5">
                    <Box>
                        <div className="helper">

                            <h2>ACCESS PERFORMANCES</h2>
                            {/* <p className="mb-0"><strong>Relaxed Performance</strong></p>
                            <Link to="/london/access/">
                                <p className="mb-2">Sunday 12 March 2023</p>
                            </Link>
                            <StyledButton target="_blank" rel=" noopener" href="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/performance/b43710e6-5b75-ed11-83d1-ce3cbd348342?externalId=1674573109394.224910060618&sineFbp=fb.1.1671535301603.1068640786&_ga=2.23172218.1153042709.1675156443-2051954772.1671535301" >
                                Book Now
                            </StyledButton> */}
                            {/* <p className="mb-0 mt-3"><strong>Signed Performance</strong></p>
                            <a href="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/performance/df37f784-8a3e-45de-8c39-222d4eca79eb?externalId=1669027857822.64714366346&sineFbp=fb.1.1661159099826.1490032607&_ga=2.45193636.1077532095.1670322885-414700929.1661159099" target="_blank">
                                <p className="mb-2">Sunday 2 April 2023</p>
                            </a> */}
                            {/* <p className="mb-0 mt-3"><strong>Relaxed Performance</strong></p>
                            <a href="https://ticketing.lwtheatres.co.uk/event/218/performance/44321" target="_blank" rel="noopener ">
                                <p className="mb-2">Sunday 10 March 2024</p>
                            </a> */}
                            {/* <p className="mb-0 mt-3"><strong>Signed Performance</strong></p>
                            <a href="https://ticketing.lwtheatres.co.uk/event/218/performance/55852" target="_blank" rel="noopener ">
                                <p className="mb-2">Sunday 15th September 2024</p>
                            </a> */}
                            <p className="mb-0 mt-3"><strong>Relaxed Performance</strong></p>
                            <a href="https://ticketing.lwtheatres.co.uk/event/218/performance/55985" target="_blank" rel="noopener ">
                                <p className="mb-2">Sunday 9 March 2025</p>
                            </a>
                            <StyledButton to="/london/access/" >
                                Find out More
                            </StyledButton>
                        </div>
                    
                    </Box>
                    <Box>
                        <div className="helper">
                            <h2>Groups</h2>
                            <p><strong>NOW BOOKING UNTIL 12 APRIL 2026</strong></p>
                            <p><strong className='accelerator'>ACCELERATOR RATE</strong><em><br /></em>BOOK AND PAY BY 4 APRIL 2025</p>
                            <p className="uppercase"><strong>GROUPS OF 10+</strong><br />Stalls & Dress Circle £39.50</p>
                            <p className="small">Valid on Mon-Thurs performances 6 January 2025 – 16 October 2025, excluding peak performances*</p>
                            <p className='uppercase'><strong>GROUPS OF 10+ | FRIDAY</strong> <br />Stalls & Dress Circle £50</p>
                            <p className='small'><em>Valid on Fri performances 10 January 2025 – 17 October 2025, excluding peak performances*</em></p>
                            <p className='uppercase'><strong>GROUPS OF 10+ | SUNDAY AND SELECT PEAK WEEK PERFORMANCES</strong> <br />Stalls & Dress Circle £55</p>
                            <p className='small'><em>Valid on Sunday performances  and Mon-Fri peak performances*, excluding w/c 17 February, w/c 7 April, w/c 14 April, Sun 16 Feb, Sun 6 April, Sun 4 May, Sun 25 May
                            </em></p>
                            <p className='small'>*Peak performances: 17- 23 February, 7- 21 April,  5 May, 26 May – 1 June, 21 July – 1 September</p>
                            <hr />
                            {/* <p><strong className='uppercase'>Book and pay by 21 October 2024</strong></p>
                            <p>Valid 28 July – 19 October 2025</p>
                            <p>Peak weeks 28th July – 1st September 2024</p>
                            <hr /> */}
                            <p><strong>Groups 6+ £50<br /></strong>Valid Mon-Thurs off peak performances <br />
                            <a href="https://ticketing.lwtheatres.co.uk/event/218?PROMO=BTTFSIX" target='_blank' className="block mt-2">
                            <p><strong>BOOK HERE</strong></p>
                            </a></p>
                            <p><strong>Groups 6+ £55<br /></strong>Valid Friday and Sunday performances <br />
                            <a href="https://ticketing.lwtheatres.co.uk/event/218?PROMO=BTTFSIX" target='_blank' className="block mt-2">
                            <p><strong>BOOK HERE</strong></p>
                            </a></p>
                            
                            <p><strong>GROUPS OF 8+<br /></strong>STALLS &amp; DRESS CIRCLE - &pound;55.00</p>
                            <p><strong>GROUPS OF 20+<br /></strong>STALLS &amp; DRESS CIRCLE - &pound;45.00<br />UPPER CIRCLE - &pound;35.00</p>
                            <p className="small">Valid on Monday to Thursday, excluding peak performances</p>
                            <p><strong>FRIDAY AND PEAK PERFORMANCES</strong></p>
                            <p><strong>GROUPS OF 8+<br /></strong>STALLS AND DRESS CIRCLE - &pound;62.50</p>
                            <p><strong>GROUPS OF 20+<br /></strong>STALLS AND DRESS CIRCLE - &pound;57.50</p>
                            <p><strong>SUNDAY OFF PEAK PERFORMANCES<br /></strong></p>
                            <p><strong>GROUPS OF 8+<br /></strong>STALLS AND DRESS CIRCLE - &pound;69.50</p>
                            <p><strong>GROUPS OF 20+<br /></strong>STALLS AND DRESS CIRCLE - &pound;59.50</p>
                            <p className="black">
                                <a className="font-weight-bold font-italic link--black"
                                   href="mailto:groups@adelphitheatre.co.uk">groups@adelphitheatre.co.uk</a><br/>
                                <a className="font-weight-bold font-italic link--black" href="tel:02070877763">020 7087
                                    7763</a>
                            </p>
                            <p className="small">Peak week group rates apply to Mon-Thu performances in weeks commencing: 17 February 2025. Week commencing 7 & 14 April, 26 May, performances 28 July to 1 September  2025</p>
<p className="small">Sunday Off Peak. Rear stalls and dress only. Excluding 16 February, 9 March, 6, 13 & 20 April, 25 May, 27 July, 3, 10, 17, 24 & 31 August 2025</p>
<p className="small">Friday performance rates do not apply in peak weeks.</p>
                            {/* <p className="black font-italic text-uppercase">Now Booking Until 21 July 2024</p> */}
                            {/*<p className="text-uppercase">10+ £39.50, Stalls and Dress Circle</p>
                            <p className="small">*Available Monday – Wednesday eves + Thursday matinees from 28 February – 30 June. Book and pay by 31st March - excluding peak performances.</p> */}

                            {/* <p className="black font-italic text-uppercase mb-0">Groups of 8+</p>
                            <p className="text-uppercase mb-2">Stalls & Dress Circle - £55.00</p>
                            <p className="black font-italic text-uppercase mb-0">Groups of 20+</p>
                            <p className="text-uppercase mb-0">Stalls & Dress Circle - £45.00</p>
                            <p className="text-uppercase mb-0">Upper Circle - £35.00</p>
                            <p className="small mt-3">Valid on Monday to Thursday, excluding peak performances</p>
                            <p className="black font-italic text-uppercase mb-3">FRIDAY AND PEAK PERFORMANCES</p>

                            <p className="black font-italic text-uppercase mb-0">GROUPS OF 8+</p>
                            <p className="text-uppercase">STALLS AND DRESS CIRCLE - £62.50</p>

                            <p className="black font-italic text-uppercase mb-0">GROUPS OF 20+</p>
                            <p className="text-uppercase">STALLS AND DRESS CIRCLE - £57.50</p>
                            <p className="text-uppercase black font-italic mb-3">SUNDAY OFF PEAK PERFORMANCES</p>

                            <p className="black font-italic text-uppercase mb-0">GROUPS OF 8+</p>
                            <p className="text-uppercase">STALLS AND DRESS CIRCLE - £72.50</p>

                            <p className="black font-italic text-uppercase mb-0">GROUPS OF 20+</p>
                            <p className="text-uppercase">STALLS AND DRESS CIRCLE - £62.50</p>

                            <p className="black">
                                <a className="font-weight-bold font-italic link--black"
                                   href="mailto:groups@adelphitheatre.co.uk">groups@adelphitheatre.co.uk</a><br/>
                                <a className="font-weight-bold font-italic link--black" href="tel:02070877763">020 7087
                                    7763</a>
                            </p>

                            <p className="small">Peak week group rates apply to Mon-Thu performances in weeks commencing: 14 August, 21 August, 28 August, 16 October, 23 October 2023, 1 January, 12 February, 8th April, 27th May 2024.</p>
                            <p className="small">The group rate is not available for performances between 17 and 31 December 2023.</p>
                            <p className="small">Friday performance rates do not apply in peak weeks.</p> */}
                        </div>
                    </Box>

                    <Box>
                        <div className="helper">
                            <h2>Education</h2>
                            <p>Education rates apply to  Mon-Thurs performances in off peak weeks.</p>
                            <p className="black font-italic text-uppercase mb-0">GROUPS OF 10+</p>

                            <p className="text-uppercase mb-0">UPPER CIRCLE – £25</p>
                            <p className="text-uppercase mb-0">REAR DRESS CIRCLE AND STALLS - £35</p>
                            <p>Plus one teacher goes free for every ten tickets booked.</p>

                            <p className="black">
                                <a className="font-weight-bold font-italic link--black"
                                   href="mailto:groups@adelphitheatre.co.uk">groups@adelphitheatre.co.uk</a><br/>
                                <a className="font-weight-bold font-italic link--black" href="tel:08008527244">0800 852
                                    7244</a>
                            </p>
                            <StyledButton to="/london/education/" >
                                EDUCATION RESOURCES 
                            </StyledButton>
                            <p className="small mt-4">Education rates are not available in peak weeks (all performances weeks commencing 21 Oct, 28 Oct, 16 Dec, 23 Dec, 30 Dec 2024, and week commencing 17 Feb 2025).</p>
                        </div>
                    </Box>

                </Content>
                <LogoWrapper>
                    <div className="helper">
                    <div className="item">
                        <div className="imageWrapper">
                            <a href="https://www.todaytix.com/london/shows/22396-back-to-the-future" target="_blank" rel=" noopener" >
                                <img  src={todaytix} alt="Todaytix" title="Todaytix"/>
                            </a>
                        </div>
                        <p className="color-white mt-4 narrow"><span className="font-italic bold">RUSH TICKETS</span><br/>
                        For every performance, a limited number of £29.50 rush tickets will be available from 10am in the TodayTix app.</p>
                        <div className="pt-5">
                            <a href="https://play.google.com/store/apps/details?id=com.todaytix.TodayTix&hl=en_GB&gl=US" className="mx-2 pb-2 pb-lg-0 d-inline-block" target="_blank" rel=" noopener" >
                                <img src={googlePlay} alt="Download it from Google Play" title="Download it from Google Play"/>
                            </a>
                            <a href="https://apps.apple.com/gb/app/todaytix-london-tickets/id648450205" className="mx-2 pb-2 pb-lg-0 d-inline-block" target="_blank" rel=" noopener" >
                                <img src={appStore} alt="Download it from App Store" title="Download it from App Store"/>
                            </a>
                        </div>
                    </div>
                    </div>
                </LogoWrapper>
                {/* <LogoWrapper second>
                    <div className="helper">
                    <div className="item">
                        <div className="imageWrapper">
                            <a href="https://www.holidayextras.com/uk-short-breaks/theatre-breaks/back-to-the-future-the-musical-shortbreak.html#utm_source=ww_backtothefuture&utm_medium=referral&utm_campaign=ttw_breaks_ticketoptions" target="_blank" rel=" noopener" >
                                <img  src={hotel} alt="Todaytix" title="Todaytix"/>
                            </a>
                        </div>
                        <p className="color-white mt-4  mb-4"><span className="font-italic bold">TICKETS & HOTEL PACKAGE</span><br/>
                        Find out more about hotel and ticket packages with Holiday Extras.</p>
                        <StyledButton target="_blank" rel=" noopener" href="https://www.holidayextras.com/uk-short-breaks/theatre-breaks/back-to-the-future-the-musical-shortbreak.html?utm_source=ww_backtothefuture&utm_medium=referral&utm_term=clean_white_label" >
                        BOOK YOUR PACKAGE NOW
                            </StyledButton>
                    </div>
                    </div>
                </LogoWrapper> */}

                <CustomerModal toggleModal={this.toggleCustomerModal} modalOpen={this.state.customerModalOpen}/>
            </Wrapper>
        );
    }
}

export default GroupsBlock
